/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://pn6w65tn3zbs7bc5tpzfhru73i.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_C3ClEAbPp",
    "aws_user_pools_web_client_id": "2fgibe3bg46e1d0fsv6lbrk1mb",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "AuditTrail-AuthenticatedOperations-devback",
            "region": "us-east-1"
        },
        {
            "tableName": "AuditTrail-EventNames-devback",
            "region": "us-east-1"
        },
        {
            "tableName": "BQAnalyticsCategories-devback",
            "region": "us-east-1"
        },
        {
            "tableName": "BQAnalyticsEventLog-devback",
            "region": "us-east-1"
        },
        {
            "tableName": "BQAnalyticsExercises-devback",
            "region": "us-east-1"
        },
        {
            "tableName": "BQAnalyticsPatients-devback",
            "region": "us-east-1"
        },
        {
            "tableName": "BQAnalyticsTreatments-devback",
            "region": "us-east-1"
        },
        {
            "tableName": "BQAppEvents-devback",
            "region": "us-east-1"
        },
        {
            "tableName": "MobileDeviceInfo-devback",
            "region": "us-east-1"
        },
        {
            "tableName": "SharedData-devback",
            "region": "us-east-1"
        },
        {
            "tableName": "SimMapping-devback",
            "region": "us-east-1"
        },
        {
            "tableName": "TreatmentDefinitions-devback",
            "region": "us-east-1"
        },
        {
            "tableName": "UniqueValues-devback",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "bq-content220346-devback",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
